import React from "react";

const DemoIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18.000000pt"
      height="18.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="white"
        stroke="none"
      >
        <path
          d="M580 4291 c-164 -52 -299 -204 -330 -370 -6 -34 -10 -482 -10 -1207
l0 -1154 -80 0 c-91 0 -126 -12 -146 -51 -44 -87 22 -320 129 -454 91 -113
201 -187 337 -224 73 -20 96 -21 873 -21 l799 0 29 29 c40 40 40 89 0 133
l-29 33 -809 5 -808 5 -68 32 c-113 53 -214 170 -247 282 l-9 31 850 0 849 0
0 -45 c0 -55 29 -90 81 -100 19 -3 272 -5 562 -3 l529 3 29 33 c22 24 29 42
29 72 l0 40 884 0 885 0 -8 -28 c-27 -93 -105 -197 -190 -251 -108 -70 -51
-65 -939 -71 l-804 -5 -29 -33 c-40 -44 -40 -93 0 -133 l29 -29 797 0 c883 0
852 -2 991 66 127 62 229 165 295 299 61 124 86 274 55 334 -20 39 -55 51
-146 51 l-80 0 0 1158 c0 1272 2 1224 -61 1343 -34 63 -115 149 -175 186 -22
13 -68 32 -103 43 -62 20 -95 20 -1985 19 -1807 0 -1924 -1 -1976 -18z m3926
-200 c58 -27 111 -77 142 -135 l27 -51 3 -1172 2 -1173 -110 0 -110 0 -2 1109
-3 1109 -31 39 c-16 21 -50 48 -74 61 l-44 22 -1746 0 -1746 0 -44 -22 c-24
-13 -58 -40 -74 -61 l-31 -39 -3 -1109 -2 -1109 -110 0 -110 0 2 1173 c3 1098
4 1174 21 1210 27 57 86 118 142 143 l50 24 1905 0 c1831 0 1907 -1 1946 -19z
m-246 -1461 l0 -1070 -626 0 -626 0 -34 -34 c-28 -28 -34 -42 -34 -75 l0 -41
-414 0 -413 0 -7 49 c-5 39 -13 55 -37 75 l-31 26 -589 0 -589 0 0 1070 0
1070 1700 0 1700 0 0 -1070z"
        />
        <path
          d="M2555 3046 c-59 -26 -63 -37 -121 -352 -30 -164 -54 -316 -54 -337 0
-29 7 -46 29 -68 40 -39 89 -40 130 -1 27 24 33 41 53 152 l24 125 33 -115
c43 -146 52 -163 97 -185 30 -14 43 -15 74 -6 20 6 45 20 55 31 10 11 35 77
55 147 l37 127 11 -49 c6 -28 16 -79 22 -115 19 -108 45 -140 112 -140 42 0
72 20 88 60 12 28 7 65 -46 343 -65 341 -73 363 -137 384 -43 14 -88 2 -119
-33 -9 -11 -36 -88 -61 -173 -24 -85 -46 -150 -49 -145 -3 5 -25 74 -48 154
-23 80 -49 153 -58 164 -16 18 -72 46 -89 46 -4 -1 -21 -7 -38 -14z"
        />
        <path
          d="M3598 3049 c-75 -11 -162 -56 -221 -115 -154 -155 -153 -405 3 -561
197 -197 523 -139 643 115 29 61 32 75 32 167 0 88 -3 107 -27 157 -36 77 -70
121 -129 164 -85 62 -193 88 -301 73z m171 -227 c14 -10 39 -35 55 -56 28 -34
31 -44 31 -110 0 -60 -4 -78 -24 -108 -94 -136 -292 -118 -355 34 -33 79 -7
174 65 230 60 48 169 53 228 10z"
        />
        <path
          d="M1858 3021 l-33 -29 -3 -314 c-4 -349 0 -377 51 -408 28 -17 51 -20
170 -20 190 0 227 17 227 108 0 28 -7 45 -26 63 -22 21 -37 25 -122 29 l-97 5
-3 48 -3 47 75 0 c92 0 120 10 146 52 27 43 20 84 -20 121 -28 25 -37 27 -115
27 l-85 0 0 50 0 50 96 0 c92 0 97 1 125 29 38 39 40 97 5 138 l-24 28 -165 3
-165 3 -34 -30z"
        />
        <path
          d="M1164 3025 c-42 -29 -45 -55 -42 -389 l3 -318 34 -30 33 -30 142 4
c130 3 145 6 192 30 66 35 137 114 166 186 19 48 23 75 23 177 0 103 -4 128
-24 180 -33 82 -116 164 -190 188 -71 23 -306 24 -337 2z m281 -197 c44 -20
68 -72 73 -158 8 -135 -46 -210 -150 -210 l-48 0 0 190 0 190 50 0 c27 0 61
-5 75 -12z"
        />
        <path
          d="M2523 995 c-99 -43 -71 -185 36 -185 96 0 138 124 59 173 -37 24 -61
27 -95 12z"
        />
      </g>
    </svg>
  );
};

export default DemoIcon;
